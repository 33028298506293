import { css } from '@emotion/react'
import {
  ComponentPropsWithoutRef,
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
} from 'react'
import { useInView } from 'react-intersection-observer'

import { ExternalVideoBlock } from '@/features/common/'
import { useElementHeight } from '@/hooks/useElementRect'
import { absoluteFill, bezier, scrim } from '@/theme/mixins'
import { colors } from '@/theme/variables'

import type { MediaCarouselLayout } from './MediaCarousel'

interface Props extends ComponentPropsWithoutRef<'figure'> {
  data?: Queries.ExternalVideoBlockFragment | null
  layout: MediaCarouselLayout
  carouselHeight: number | null
  setCarouselHeight?: Dispatch<SetStateAction<number | null>>
}

export const MediaCarouselVideo = ({
  data,
  layout,
  carouselHeight,
  setCarouselHeight,
  ...props
}: Props): JSX.Element => {
  const { inView, ref: inViewRef } = useInView({
    rootMargin: '20% -50%',
  })
  const [heightRef, setHeightRef] = useState<HTMLElement | null>(null)
  const height = useElementHeight(heightRef)

  useEffect(() => {
    if (setCarouselHeight && inView && height) {
      setCarouselHeight(height)
    }
  }, [inView, height, setCarouselHeight])

  const styles = {
    figure: css`
      width: 100%;
      position: relative;
      margin: var(--border-thickness) 0;
      align-self: flex-start;
      ${layout === 'ARTICLE' &&
      css`
        &:before {
          content: '';
          position: absolute;
          top: calc(-1 * var(--border-thickness));
          left: calc(-1 * var(--border-thickness));
          width: calc(100% + 2 * var(--border-thickness));
          height: calc(100% + 2 * var(--border-thickness));
          background: ${colors.gray95};
        }
        &:after {
          content: '';
          position: absolute;
          height: 1rem;
          width: 100%;
          background: ${colors.gray95};
          top: ${carouselHeight &&
          height &&
          Math.min(carouselHeight, height)}px;
          left: 0;
          transition: top 750ms ease;
        }
      `}
      ${layout === 'HIGHLIGHT' &&
      css`
        display: grid;
        height: 100%;
      `}
    `,
    opacityWrapper: css`
      position: relative;
      opacity: 0.125;
      transition: opacity 500ms ${bezier.easeOut};
      pointer-events: none;
      ${inView &&
      css`
        opacity: 1;
        transition-duration: 1000ms;
        pointer-events: all;
        &:after {
          opacity: 0;
        }
      `}
      ${layout === 'HIGHLIGHT' &&
      css`
        display: grid;
        align-items: center;
        background: ${colors.gray10};
        grid-area: 1 / 1 / 2 / 2;
      `}
    `,
    video: css``,
    figcaption: css`
      position: relative;
      font-size: var(--fs-15);
      font-style: italic;
      color: ${colors.gray20};
      margin: 1rem 0 0;
      max-width: 108ch;
      transition: opacity 300ms ease;
      opacity: ${inView ? 1 : 0};
      ${layout === 'HIGHLIGHT' &&
      css`
        grid-area: 1 / 1 / 2 / 2;
        align-self: flex-end;
        padding: 4em 1em 2rem calc(9.25rem + 1em);
        position: relative;
        > span {
          position: relative;
          color: #fff;
        }
        &:before {
          content: '';
          ${absoluteFill};
          background: ${scrim('#000', 0.75, 'to top')};
        }
      `}
    `,
  }

  return (
    <figure
      css={styles.figure}
      ref={node => {
        setHeightRef(node)
        inViewRef(node)
      }}
      data-inview={inView}
      {...props}
    >
      <div css={styles.opacityWrapper}>
        <ExternalVideoBlock
          data={data}
          playerProps={{ controls: true }}
          css={styles.video}
        />
      </div>
      {data?.title && (
        <figcaption css={styles.figcaption}>
          <span>{data?.title}</span>
        </figcaption>
      )}
    </figure>
  )
}
